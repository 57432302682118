import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'
import { parseQueryString } from '@shein/common-function'


export function handlePrefetchResource(el) {
  const isLogin = isLoginFn()
  if (!isLogin) { // 未登录用户
    // el 必须是渲染完的
    prefetchResource.listen({
      el,
      prefetchList: [
        {
          chunkName: 'login-module',
          relType: 'prefetch'
        }
      ],
      prefetchCallback: ({ status, info }) => {
        console.log('prefetchCallback', status, info)
      },
      delay: 2000, // 默认两秒
      ioCallback: () => {
        const { needPopLogin } = parseQueryString(location.search)
        if(needPopLogin == 1) return
        if (!isLogin && !(window._gb_app_ && _gb_app_?.$store?.state?.isShowLoginModal)) {
          SHEIN_LOGIN.show({ show: false, silence: true })
        }
      }
    })
  }
}
